import React, { useContext } from 'react';
import { useLottie } from "lottie-react";
import GlobalContext from '../CommonMethods/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { useVarDispatch } from '../redux/hooks';
import { handleLoggedInState } from '../redux/persistedStates';


type lottieAnimation ={
    animateFile:object;
}
function LottieAnimation({animateFile}:lottieAnimation) {
    const dispatch = useVarDispatch()
    const navigate = useNavigate()
    const {setCurrentScreen} = useContext(GlobalContext)
    const options = {
        animationData: animateFile,
        loop: false,
        onComplete: function(){ // code here
            setCurrentScreen('LoggedIn');
            dispatch(handleLoggedInState(true))
            navigate('/')
        },
    
    };
    const { View } = useLottie(options);
    return (
        <>
            {View}
        </>
       

    );
}

export default LottieAnimation;