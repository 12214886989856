import React, {} from 'react';
//import { Button } from '@filament/react';
import { Default } from './Sampletab';

// import { Default } from './Sampletab';


const UserDrawer = () => {
    //const [isSidebarOpen, setSidebarOpen] = useState(false);

    // const toggleSidebar = () => {
    //     setSidebarOpen((prev) => !prev);
    // };

  

    return (
        <div>
            {/* Button to toggle sidebar */}
           

            {/* Sidebar */}
            {(
                <div
                   
                >
                    {/* Sidebar content */}
                   
                    <Default/>
                  
                </div>
            )}
        </div>
    );
};

export default UserDrawer;
