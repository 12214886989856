import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import { privalgeValues } from '../pages/Organization/Components/SelectPrevilageModal'


const initialState:privalgeValues={
    ScopeOfPrivilege:"",
    SelectSite:"All",
    ExpiryOfPrivilage:'Infinite',
    Date:new Date(),
    DelegatePower:'None',
    NumberOfUsers:0,
}

const SelectPrivilageSlice=createSlice({
    name:"SelectPrivilageSlice",
    initialState,
    reducers:{
        StoreSelectPrivilage:(state,action:PayloadAction<privalgeValues>)=>{
            state.ScopeOfPrivilege=action.payload.ScopeOfPrivilege
            state.SelectSite=action.payload.SelectSite
            state.ExpiryOfPrivilage=action.payload.ExpiryOfPrivilage
            state.Date=action.payload.Date
            state.DelegatePower=action.payload.DelegatePower
            state.NumberOfUsers=action.payload.NumberOfUsers
        }
    }
})

export const{StoreSelectPrivilage}=SelectPrivilageSlice.actions

export default SelectPrivilageSlice.reducer