/* eslint-disable max-len */
// import React from "react"
// import { Grid, GridItem, } from "@chakra-ui/react"
// // useDisclosure add above when using the constant
// import Navbar from "./Navbar.tsx"
// // import SiteListing from "../pages/Sites/SiteManagement/listing.tsx"
// import Organisation from "../pages/Organization/index.tsx"
// // import { Small } from "./SideNav.tsx"
// import SideBar from "./Sidebar.tsx"

// export default function RootLayout(){
//     // const { isOpen ,onToggle} = useDisclosure() uncomment when used in nave bar.
//     return (
//         <>
//             <Grid 
//                 templateAreas={{base:`'header header header'
//                                'main main main'`,
//                 md:`'header header header'
//                                'navbar main main'`
//                 }}
                
//                 templateRows={'56px 1fr'}
//                 templateColumns='72px 1fr'
//                 height='100vh'
//                 overflowX={'hidden'}
//             >
//                 {/*  isOpen={isOpen} onToggle={onToggle} add to navbar */}
//                 <GridItem top={'0'} left={'0'} width={'full'} gridArea='header' bg={'#0B5ED7'}><Navbar /></GridItem>
//                 <GridItem  gridArea='navbar' display={{base:"none",md:'block'}} borderRightWidth="1px" borderBottomColor="#CAD2DB" bg='white.200'><SideBar/></GridItem>  
//                 <GridItem  gridArea='main' height='full' width='full' overflowX={'hidden'} ><Organisation /></GridItem> 
//             </Grid>
           
            
//         </>
//     )
// }


// Note
// this below needs to be commment if using the above grid. also remember the above code of grid isnt converted to filament it is still in chakra ui.
// incase u uncomment this part of the code later on it might through error.
import React, { useEffect } from "react";
import { useState } from 'react';
import { FlexBox } from "@filament/react";
import Navbar from "./Navbar.tsx";
import { SideNav } from "./SideNav.tsx";
import WelcomeScreen from "./WelcomeScreen.tsx";

export default function RootLayout() {
    const [showWelcome,setShowWelcome] = useState(true);

    // // Use useEffect to delay the transition from welcome screen to main content
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowWelcome(false);
        }, 8000); // 5000 ms = 5 seconds

        // Cleanup the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {showWelcome ? (
           
                <WelcomeScreen/>
                
            ) : (
                <FlexBox style={{ display: "flex", flexDirection: "column", width: "full" }}>
                    <Navbar />
                    <SideNav />
                </FlexBox>
            )}
        </>
      
    );
}
