/* eslint-disable max-len */

// Note
// this is an alternative sidebar used when grid is not used. if you want to use grid the use Sidebar.tsx instead of SideNav.tsx

import {
    Item,
    FlexBox,
    TabContext,
    Tabs,
    TabPanels,
    Sidebar,
    Text,
} from '@filament/react';

import React from 'react';
// import { Placeholder } from '@filament-icons/react';
import Organisation from '../pages/Organization';
import { atomicBorderBottom as borderBottom } from '@filament/react/styles';
import { atomicBorderRight as borderRight } from '@filament/react/styles';
import { Applications, Connection, Home, PlusShield } from '@filament-icons/react';

export const SideNav = () => (
    <TabContext>
        <FlexBox height="calc(100vh - 56px)" >
            <Sidebar className={borderRight}>
                <div className={borderBottom} style={{ height: '56px' }}>
                    {/* <Image src="/image/Dropdown.png" alt="" /> */}
                    <FlexBox style={{ paddingTop: "12px", paddingLeft: '12px' }} >
                        <Text
                            style={{ background: "#0B5ED7", borderRadius: '15px', color: "white", paddingTop: '4px', paddingLeft: '2px' }}
                            height='32px'
                            width='32px'
                            textAlign={'center'}
                        >JD</Text>
                        <div style={{ paddingTop: "9px", paddingLeft: '3px' }} >
                            <img src="/images/Arrow.png" alt="" height='16px' width='16px' />
                        </div>
                    </FlexBox>
                </div>
                <Tabs orientation="vertical" iconPosition="top" width={72} noBorder  >
                    <Item key="Console">
                        <Applications />
                        <span>Console</span>
                    </Item>
                    <Item key="Hub">
                        <Home />
                        <span>Hub</span>
                    </Item>
                    <Item key="Security">
                        <PlusShield />
                        <span>Security</span>
                    </Item>
                    <Item key="Org">
                        <Connection />
                        <span>Org</span>
                    </Item>
                </Tabs>
            </Sidebar>
            <TabPanels
                flex={1}
            >
                <Item key="Console">Tab 1 Content</Item>
                <Item key="Hub">Tab 2 Content</Item>
                <Item key="Security">Tab 3 Content</Item>
                <Item key="Org"><Organisation /></Item>
            </TabPanels>
        </FlexBox>
    </TabContext>
);