/* eslint-disable max-len */
import React, { useCallback, useState } from 'react';
import {
    Cell,
    Column,
    DataGrid,
    Pagination,
    Row,
    Table,
    TableBody,
    TableHeader,
    Avatar,
    Text,
    FlexBox,
} from '@filament/react';
import { Plus, Eye, ThreeDotsVertical, ChevronUpDown } from '@filament-icons/react';
import { useGetOrgUsersListQuery } from '../../../../graphql/generated';
import DateDisplay from '../../../../CommonMethods/DateDisplay';
import {
    // atomicIconLarge as iconLarge,
    atomicIconSmall as iconSmall,
} from '@filament/react/styles';
import { UserInformation } from '../Components/ViewUser';
import Loading from '../../../../components/Loading';

export default function UserTable() {
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedUser, setSelectedUser] = useState(null);

    const { data, loading, error } = useGetOrgUsersListQuery({
        variables: {
            organization_id: '673582adeed3cccc395494ae',
            page: currentPage + 1, // API page starts from 1
            limit: pageSize,
            searchQuery: "",
            sortField: "createdAt",
            sortDirection: "desc",
        },
    });

    const handlePageSizeChange = useCallback(
        (size: number) => {
            setPageSize(size);
            setCurrentPage(0);
        },
        []
    );

    const handleCurrentPageChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
        },
        []
    );

    const handleViewClick = (user: React.SetStateAction<null>) => {
        setSelectedUser(user);
    };

    const handleCloseModal = () => {
        setSelectedUser(null);
    };

    // Conditionally render loading or error states after hooks
    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // Extract user list and total count from data
    const userList = data?.orgUsersList?.data || [];
    const totalCount = data?.orgUsersList?.totalCount ?? 0;

    return (
        <div style={{ position: 'relative' }}>
            <DataGrid>
                <div className="scrollable-element" style={{ height: '585px', overflow: 'auto' }} >
                    <Table aria-label="Pagination Table" width="100%" hasStickyHeader >
                        <TableHeader>
                            <Column width={240} key="name"><Text color='secondary' variant='body-s'>Name</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={190} key="department"><Text color="secondary" variant='body-s'>Department</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={170} key="lastLogin"><Text color="secondary" variant='body-s'>Last Login</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={170} key="created"><Text color="secondary" variant='body-s'>Created</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={147} key="mfa"><Text color="secondary" variant='body-s'>MFA</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={171} key="org"><Text color="secondary" variant='body-s'>Org</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={162} key="status"><Text color="secondary" variant='body-s'>Status</Text><ChevronUpDown className={iconSmall} /></Column>
                            <Column width={180} key="privileges"><Text color="secondary" variant='body-s'>Privileges</Text><ChevronUpDown className={iconSmall} /></Column>
                            {/* eslint-disable-next-line react/no-children-prop */}
                            <Column width={0.1} children={undefined}></Column>
                        </TableHeader>
                        <TableBody>
                            {userList.map((user) => (
                                <Row key={user._id} >
                                    <Cell >
                                        <FlexBox alignItems="center" gap={8} >
                                            <Avatar width={'40px'} height={'40px'}>
                                                <img
                                                    src={'/images/Image.png'}
                                                    alt={`${user.firstName || ''}`}
                                                />
                                            </Avatar>
                                            <Text variant="body-m" width={140} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{` ${user.firstName || ''} ${user.lastName || '-'}`}</Text>
                                        </FlexBox>
                                    </Cell>
                                    <Cell ><FlexBox><Text variant="body-m" width={190} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{(user.department || '-').slice(0, 20)}</Text></FlexBox></Cell>
                                    <Cell><Text width={170} variant="body-m">{user.lastLogin && DateDisplay({ date: user.lastLogin })}</Text></Cell>
                                    <Cell>
                                        <Text width={170} variant="body-m">{user.createdAt && DateDisplay({ date: user.createdAt })}</Text>
                                    </Cell>
                                    <Cell>
                                        <Text variant="body-m">{user.mfa_enabled ? user.mfa_enabled.charAt(0).toUpperCase() + user.mfa_enabled.slice(1) : 'Unknown'}</Text>
                                    </Cell>
                                    <Cell ><FlexBox><Text variant="body-m" width={171} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{(user.organization_name || '-').slice(0, 20)}</Text></FlexBox></Cell>
                                    <Cell>
                                        <div
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                textAlign: "center",
                                                padding: "4px 8px",
                                                width: "106px", // Fixed width for equal alignment
                                                borderRadius: "8px",
                                                border: "1px solid",
                                                backgroundColor:
                                                    user.status === "active"
                                                        ? "#E6F7E9"
                                                        : user.status === "inactive"
                                                            ? "#FFF5F5"
                                                            : "#F5F5F5",
                                                color:
                                                    user.status === "active"
                                                        ? "#41B546"
                                                        : user.status === "inactive"
                                                            ? "#D53F3F"
                                                            : "#4A5568",
                                                borderColor:
                                                    user.status === "active"
                                                        ? "#0A993A"
                                                        : user.status === "inactive"
                                                            ? "#C53030"
                                                            : "#CBD5E0",
                                            }}
                                        >
                                            {user.status === "active" && (
                                                <>
                                                    <span
                                                        style={{
                                                            backgroundColor: "#41B546",
                                                            borderRadius: "50%",
                                                            width: "8px",
                                                            height: "8px",
                                                            marginRight: "8px",
                                                        }}
                                                    ></span>
                                                    <Text variant='body-m' style={{ color: "#087D2F" }}>Active</Text>
                                                </>
                                            )}
                                            {user.status === "inactive" && (
                                                <>
                                                    <span
                                                        style={{
                                                            backgroundColor: "#D53F3F",
                                                            borderRadius: "50%",
                                                            width: "8px",
                                                            height: "8px",
                                                            marginRight: "8px",
                                                        }}
                                                    ></span>
                                                    <Text variant='body-m' style={{ color: "#BB460C" }} >Inactive</Text>
                                                </>
                                            )}
                                            {user.status === "pending" && (
                                                <>
                                                    <img
                                                        src="/images/pending.png"
                                                        alt="Pending"
                                                        style={{ width: "16px", height: "16px", marginRight: "8px" }}
                                                    />
                                                    <Text variant='body-m' style={{ color: "#6B6B6B" }}>Pending</Text>
                                                </>
                                            )}
                                        </div>
                                    </Cell>

                                    <Cell>
                                        <div
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                gap: "1px",
                                                padding: "4px 8px",
                                                borderRadius: "8px",
                                                border: "1px solid",
                                                backgroundColor: user.privilegeType === "All Features" ? "#FFF5F5" : "#E6F7FF",
                                                borderColor: user.privilegeType === "All Features" ? "#C53030" : "#3182CE",
                                                color: user.privilegeType === "All Features" ? "#C53030" : "#3182CE",
                                            }}
                                        >
                                            {user.privilegeType === "All Features" ? (
                                                <span onClick={() => handleViewClick(user)}>
                                                    <FlexBox alignItems="center" gap={1} style={{ cursor: "pointer" }} >
                                                        <Plus style={{ width: '16px', height: '16px' }} />
                                                        <span style={{ padding: "0 2px" }}>Add</span>
                                                    </FlexBox>
                                                </span>
                                            ) : (
                                                <span onClick={() => handleViewClick(user)}>
                                                    <FlexBox alignItems="center" gap={1} style={{ cursor: "pointer" }}>
                                                        <Eye style={{ width: '16px', height: '16px' }} />
                                                        <span style={{ padding: "0.0px" }} >View</span>
                                                    </FlexBox>
                                                </span>
                                            )}
                                        </div>
                                    </Cell>
                                    <Cell><ThreeDotsVertical /></Cell>
                                </Row>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <Pagination
                    total={totalCount}
                    pageSize={pageSize}
                    pageSteps={[10, 30, 50]}
                    setPageSize={handlePageSizeChange}
                    currentPage={currentPage}
                    setCurrentPage={handleCurrentPageChange}
                />
            </DataGrid >
            {selectedUser && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background overlay
                        zIndex: 1000,
                    }}
                >
                    <UserInformation user={selectedUser} handleCloseModal={handleCloseModal} />
                </div>
            )
            }
        </div >
    );
}
