import { gql } from "@apollo/client";



const GET_TASKS_LIST=gql`
query getTasksList(
    $id:String!,
    $listType:String!,
){
    roleTemplateLists(
        id:$id,
        listType:$listType
    ){
        userTasksList{
      taskType,
      tasks
    }
    }
}
`


export default GET_TASKS_LIST


