import {Route, Routes, useLocation} from 'react-router-dom'
import RootLayout from '../components/RootLayout'
import React from 'react'
import * as styles from '../pages/Authentication/styles/styles.css'
import { useVarSelector } from '../redux/hooks'
import WelcomePage from '../pages/Authentication/WelcomePage'


export default function NavigationRoutes(){
    const isLoggedIn  = useVarSelector(state=>state.persistedState.isLoggedIn)
    const location = useLocation()

    return(
        <> 
            {
                <Routes location={location} key={location.pathname}>
                    {!isLoggedIn?<>
                        <Route path="/" element={<div className={styles.loginPage}><WelcomePage/></div>}/>
                                    
                    </>:<>
                        <Route path='/' element={<RootLayout/>}/>
                    </>
                    }
             

                </Routes>
            }
        </>
    ) 
}