import React from "react";
import "./App.css";
import NavigationRoutes from "./routes";


function App() {
    const navigationPath = NavigationRoutes()
    return (
        <div >
            {navigationPath}
        </div>
    );
}

export default App;
