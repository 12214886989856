/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
// import React, { useCallback, useContext, useState } from "react";
// import { Box, Button, Flex, Heading, HStack, Img, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

// import MobileSideNav from "./MobileSIdeNav.tsx";
// import { ArrowBackIcon } from "@chakra-ui/icons";
// import GlobalContext from "../CommonMethods/GlobalContext.tsx";

// import Debounce from "../CommonMethods/Debounce.ts";
// import { useVarDispatch } from "../redux/hooks.ts";
// import { handleLoggedInState } from "../redux/persistedStates.ts";
// import { useNavigate } from "react-router-dom";

// export default function Navbar({ isOpen, onToggle }: { isOpen: boolean, onToggle: () => void }) {
//     const { searchTerm, setSearchTerm, setSkip, setScreenLoading, setEmail } = useContext(GlobalContext)
//     const dispatch = useVarDispatch()
//     const navigate = useNavigate()


//     const handleSearch = (value: string) => {
//         if (value !== '') {
//             setSkip(false)
//             setScreenLoading(false)
//         }

//     };

//     const optimizedFn = useCallback(
//         Debounce({ func: (value: string) => handleSearch(value) }),
//         []
//     );
//     const [isSearchIconClicked, setIsSearchIconClicked] = useState<boolean>(false)
//     return (
//         <Box>
//             <Flex as="nav" px='8px' py="7px" mb='40px' alignItems='center' justifyContent={'space-between'}>
//                 <MobileSideNav onToggle={onToggle} isOpen={isOpen} />
//                 <Box display={isSearchIconClicked ? 'none' : 'block'}>
//                     <Img src='/images/Left.png' />
//                 </Box>
//                 <Box width={isSearchIconClicked ? '100%' : '40%'}  >
//                     <Button variant={'ghost'} display={isSearchIconClicked ? 'none' : { base: 'block', md: 'none' }} onClick={() => { setIsSearchIconClicked(prev => !prev) }}>
//                         <Img mx="auto" src={'/images/Icon.png'} width={5} height={5} />
//                     </Button>
//                     <HStack>
//                         <ArrowBackIcon display={isSearchIconClicked ? 'block' : 'none'} onClick={() => { setIsSearchIconClicked(false) }} />
//                         <InputGroup display={isSearchIconClicked ? 'block' : { base: 'none', md: 'block' }}>

//                             <InputLeftElement
//                                 pointerEvents="none" // Prevents the icon from blocking input clicks
//                             >
//                                 <Img src={'/images/Icon.png'} width={5} height={5} />
//                             </InputLeftElement>
//                             <Input _focus={{
//                                 borderColor: "#CAD2DB",  // Same border color when focused
//                                 boxShadow: "none"           // Remove the default focus outline shadow
//                             }} borderRightWidth="1px" borderTopColor="#CAD2DB" bg={'white'} borderRadius={'50px'} fontSize="15px" placeholder="Search" value={searchTerm} onChange={(e) => { optimizedFn(e.target.value); setSearchTerm(e.target.value); setSkip(true); setScreenLoading(true) }} />
//                         </InputGroup>
//                     </HStack>

//                 </Box>

//                 <Flex alignItems={'center'} display={isSearchIconClicked ? 'none' : 'block'}>
//                     <HStack spacing="20px" mr='2'>
//                         <Img src='/images/help2.png' />
//                         <Img src='/images/bell.png' />
//                         <Img src='/images/Divider.png' />
//                         <HStack>
//                             <Heading color={'white'} fontSize={'16px'} fontWeight={'700'}>John Doe</Heading>
//                             <Img src={'/images/ChevronDownWhite.png'} />
//                         </HStack>
//                         <Button bg="none"
//                             onClick={() => { dispatch(handleLoggedInState(false)); navigate('/login'); setEmail('') }}
//                         ><Img src={'/images/logouticon.png'} w="5" height="5" alt="logout" /></Button>
//                     </HStack>

//                 </Flex>
//             </Flex>
//         </Box>
//     )
// }



// filament code for navbar.



import {
    Item,
    Label,
    MenuButton,
    TopBarTitle,
    TopBarUserInfo,
    Text,
    FlexBox,
    Badge,
} from '@filament/react';
import React, { useContext } from 'react';
import { useVarDispatch } from '../redux/hooks.ts';
import { handleLoggedInState } from '../redux/persistedStates.ts';
import GlobalContext from '../CommonMethods/GlobalContext.tsx';
import {
    Lock as LogOff,
    Settings,
} from '@filament-icons/react';
import { auth } from '../pages/Authentication/WelcomePage.tsx';

export default function Navbar() {
    const date = new Date().toLocaleString();
    const dispatch = useVarDispatch();
    const { setEmail } = useContext(GlobalContext);

    const handleLogout = () => {
        dispatch(handleLoggedInState(false)); // Update logged-out state
        setEmail('');                        // Clear email context
        auth.logout({
            returnTo: process.env.REACT_APP_STAGE === 'dev' 
                ? 'https://wave-htv-dev.tpvai.com'
                : process.env.REACT_APP_STAGE === 'qa'?'https://wave-htv-qa.tpvai.com': 'http://localhost:3000',
            clientID: process.env.REACT_APP_CLIENT_ID, // Required for some configurations
        });
    
    };

    return (
        <FlexBox style={{ paddingTop: '5px', paddingLeft: '8px', paddingRight: '8px', background: '#0B5ED7', height: '56px' }}  justifyContent="space-between">
            <TopBarTitle>
                <img src='/images/Left.png' />
            </TopBarTitle>
            <TopBarUserInfo>
                <FlexBox alignItems="center" gap={20}>
                    <Label><Text variant='body-m' style={{color: 'white'}}>{date}</Text></Label>
                    <img src='/images/help2.png' />
                    <Badge position="topRight" value={1}>
                        <img src='/images/bell.png' />
                    </Badge>
                    <img src='/images/Divider.png' />
                </FlexBox>
                <MenuButton
                    aria-label="settings"
                    variant="quiet"
                    shape="square"
                    placement="bottom end"
                    buttonContent={<span> <Text  variant='body-m' weight="bold" style={{color: 'white'}}>John Doe</Text></span>}
                    // onAction={handleLogout} 
                    onAction={(key) => {
                        if (key === 'logOff') {
                            handleLogout();
                        } else if (key === 'settings') {
                            // handleSettings();
                        }
                    }}
                >
                    <Item key="settings">
                        <Settings />
                        Settings
                    </Item>
                    <Item key="logOff">
                        <LogOff />
                        <Text>Log Off</Text>
                    </Item>
                </MenuButton>
            </TopBarUserInfo>
        </FlexBox>
    );
}



//line height
//paddings in px
//placeholder should be nuegre font