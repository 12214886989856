/* eslint-disable max-len */
import React from 'react';
import {
    Button,
    TextField,
    Label,
    FlexBox,
    Item,
    Tabs,
    TabContext,
    TabPanels,
    Paragraph,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Avatar,
    Text,
} from '@filament/react';
import * as styles from './styles.css';
import { atomicBorderBottom as borderBottom, atomicBorderTop as borderTop } from '@filament/react/styles';
import { Cross } from '@filament-icons/react';
import { useGetUserOrgByIdQuery } from '../../../../graphql/generated';
import DateDisplay from '../../../../CommonMethods/DateDisplay';
import Loading from '../../../../components/Loading';

export const UserInformation = ({ user, handleCloseModal }) => {
    const { data, loading, error } = useGetUserOrgByIdQuery({
        variables: { id: user._id },
    });

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const userData = data?.UserOrgById

    return (
        <Card width={'730px'} height={'100vh'} orientation="vertical"
            style={{ borderRadius: "10px 0 0 10px", position: 'fixed', top: 0, right: 0 }}
        >
            <CardHeader height={84} className={borderBottom} style={{ padding: 0 }} >
                <FlexBox justifyContent='space-between' alignItems={'center'} style={{ paddingTop: '24px', paddingBottom: '24px', paddingLeft: '32px', paddingRight: '22px' }} >
                    <Text variant='heading-s' weight="bold"> User information </Text>
                    <Button variant={'quiet'} onPress={handleCloseModal} >  <Cross width={'24px'} height={'24px'} /> </Button>
                </FlexBox>
            </CardHeader>

            <CardBody style={{ padding: 0, gap: 0 }}>
                <FlexBox alignItems="center" style={{ paddingLeft: '24px', paddingTop: '14px', paddingBottom: '8px' }}>
                    <Avatar width={'128px'} height={'128px'}>
                        <img src={'/images/Image.png'} alt="User Avatar" />
                    </Avatar>
                    <FlexBox style={{ paddingLeft: '24px', flexDirection: 'column' }}>
                        <Text variant='heading-xs' weight='bold'>{userData?.firstName} {userData?.lastName}</Text>
                        <Text variant='body-s' style={{ color: '#566676', paddingBottom: '14px' }}>{userData?.email}</Text>
                        {/* <Text
                            style={{
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                padding: "4px 8px",
                                width: "106px", // Fixed width for equal alignment
                                height: "28px",
                                borderRadius: "8px",
                                border: "1px solid #0A993A", // Active border color
                                backgroundColor: "#E6F7E9", // Active background color
                                color: "#087D2F", // Active text color
                            }}
                        >
                            {userData?.status ? userData?.status.charAt(0).toUpperCase() + userData?.status.slice(1) :'Unknown' }
                        </Text> */}
                        <Text className={`status status-${userData?.status || 'unknown'}`}>
                            {userData?.status
                                ? userData.status.charAt(0).toUpperCase() + userData.status.slice(1)
                                : 'Unknown'}
                        </Text>
                    </FlexBox>
                </FlexBox>
                <TabContext>
                    <Tabs id='tabs'>
                        <Item key="details" ><span color='secondary'>Details</span></Item>
                        <Item key="roles"><span color='secondary'>Roles and Privileges</span></Item>
                    </Tabs>
                    <TabPanels height={475} className="scrollable-element" style={{ paddingLeft: '32px', paddingRight: '32px', paddingTop: '32px', paddingBottom: '72px', overflow: 'auto' }}>
                        {/* Details Tab */}
                        <Item key="details" >
                            <form className={styles.grid} >
                                <Label>Email</Label>
                                <TextField value={userData?.email || ''} isReadOnly isFullWidth />

                                <Label>First name</Label>
                                <TextField value={userData?.firstName || ''} isReadOnly isFullWidth />

                                <Label>Last name</Label>
                                <TextField value={userData?.lastName || ''} isReadOnly isFullWidth />

                                <Label>Organisation</Label>
                                <TextField value={userData?.organization_name || ''} isReadOnly isFullWidth />

                                <Label>Department</Label>
                                <TextField value={userData?.department || ''} isReadOnly isFullWidth />

                                <Label>Job title</Label>
                                <TextField value={userData?.jobTitle || ''} isReadOnly isFullWidth />

                                <Label>MFA</Label>
                                <Text
                                    style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        padding: "4px 8px",
                                        height: "40px",
                                        width: '106px',
                                        borderRadius: "8px",
                                        backgroundColor: "#E8EEF4", // Background color for disabled
                                        color: "#15191E", // Text color for disabled
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {userData?.mfa_enabled ? userData?.mfa_enabled.charAt(0).toUpperCase() + userData?.mfa_enabled.slice(1) : 'Unknown'}
                                </Text>

                                <Label>Last login</Label>
                                <TextField value={userData?.lastLogin ? userData?.lastLogin && DateDisplay({ date: userData.lastLogin }) : 'Unknown'} isReadOnly isFullWidth />

                                <Label>Created</Label>
                                <TextField value={userData?.createdAt ? userData?.createdAt && DateDisplay({ date: userData.createdAt }) : 'Unknown'} isReadOnly isFullWidth />

                                <Label>Account</Label>
                                <Text
                                    style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        padding: "4px 8px",
                                        height: "40px",
                                        width: '106px',
                                        borderRadius: "8px",
                                        backgroundColor: "#E8EEF4", // Background color for disabled
                                        color: "#15191E", // Text color for disabled
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {userData?.status ? userData?.status.charAt(0).toUpperCase() + userData?.status.slice(1) : 'Unknown'}
                                </Text>
                            </form>
                        </Item>

                        {/* Roles and Privileges Tab */}
                        <Item key="roles">
                            <Paragraph>Roles and privileges information goes here.</Paragraph>
                        </Item>
                    </TabPanels>
                </TabContext>
            </CardBody>
            <CardFooter height={'72px'} className={borderTop} style={{ padding: 0 }}>
                <span style={{ paddingTop: '16px', paddingBottom: '16px', paddingRight: '16px', paddingLeft: '645px' }}>
                    <Button variant="primary" ><Text variant='body-m' weight='bold' style={{ color: 'white' }}>Edit</Text></Button>
                </span>
            </CardFooter>
        </Card>
    );
};
