import {createSlice, PayloadAction} from '@reduxjs/toolkit'


export type initialType = {
    isLoggedIn:boolean,
  
}
const initialState :initialType= {
    isLoggedIn:false,
    
}

const persistedSlice = createSlice({
    name:'persistSlice',
    initialState,
    reducers:{
        handleLoggedInState:(state,action:PayloadAction<boolean>)=>{
            state.isLoggedIn=action.payload
            console.log("aa",action.payload)
        },
        
    }


})

export const {handleLoggedInState} = persistedSlice.actions
export default persistedSlice.reducer
