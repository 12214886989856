export type SiteDetailType = {
    "siteImage":string
    "name": string;
    "country": string;
    "city": string;
    "displayCount":number;
    'taskStatus':string,
    "owner":{"name":string,"email":string};
    "chainManager":{"name":string,"email":string};
    "asignee":{"name":string,"email":string};
    "status":string;
}

export const SiteDetails:SiteDetailType[]=[
    {
        siteImage:'/images/image 10.png',
        name:'Accor',
        city: 'Kansas',
        country:'USA',
        owner:{name:'Sam John',email:'sam@accor.com'},
        chainManager:{name:'Sam John',email:'sam@accor.com'},
        taskStatus:'Claiming Ongoing',
        asignee:{name:'Sam samuel',email:'sam@claimer.com'},
        displayCount:12,
        status:'Stable'

    },
    {siteImage:'/images/image 11 (2).png',
        name:'21C',
        city: 'Texas',
        country:'USA',
        owner:{name:'Sam John',email:'sam@accor.com'},
        chainManager:{name:'Sam John',email:'sam@accor.com'},
        taskStatus:'Servicing Ongoing',
        asignee:{name:'Sam samuel',email:'sam@claimer.com'},
        displayCount:118,
        status:'Attention'
    },

    {
        siteImage:'/images/image 11 (2).png',
        name:'21C',
        city: 'NY',
        country:'USA',
        owner:{name:'Sam John',email:'sam@accor.com'},
        chainManager:{name:'Sam John',email:'sam@accor.com'},
        taskStatus:'',
        asignee:{name:'',email:''},
        displayCount:118,
        status:'Attention'
    },
    {
        siteImage:'/images/image 11 (2).png',
        name:'21C',
        city: 'Long Island',
        country:'USA',
        owner:{name:'Sam John',email:'sam@accor.com'},
        chainManager:{name:'Sam John',email:'sam@accor.com'},
        taskStatus:'',
        asignee:{name:'',email:''},
        displayCount:118,
        status:'Stable'
    },
    {
        siteImage:'/images/image 10.png',
        name:'Accor',
        city: 'Cross',
        country:'USA',
        owner:{name:'Sam John',email:'sam@accor.com'},
        chainManager:{name:'Sam John',email:'sam@accor.com'},
        taskStatus:'Claiming Ongoing',
        asignee:{name:'Power Joee',email:'power@claimer.com'},
        displayCount:12,
        status:'Stable'

    },
    {
        siteImage:'/images/image 11 (2).png',
        name:'21C',
        city: 'NY',
        country:'USA',
        owner:{name:'Sam John',email:'sam@accor.com'},
        chainManager:{name:'Sam John',email:'sam@accor.com'},
        taskStatus:'',
        asignee:{name:'',email:''},
        displayCount:118,
        status:'Attention'
    },
    {
        siteImage:'/images/image 11 (2).png',
        name:'21C',
        city: 'NY',
        country:'USA',
        owner:{name:'Sam John',email:'sam@accor.com'},
        chainManager:{name:'Sam John',email:'sam@accor.com'},
        taskStatus:'',
        asignee:{name:'',email:''},
        displayCount:118,
        status:'Attention'
    },
]