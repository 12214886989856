// Loading.js
import React from 'react';
import { Spinner, Paragraph } from '@filament/react';

const Loading = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            // backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Dark background for overlay
            zIndex: 1000, // Ensure it's above other elements
        }}
    >
        <div style={{ textAlign: 'center' }}>
            <Spinner size="normal" />
            <Paragraph>Loading...</Paragraph>
        </div>
    </div>
);

export default Loading;
