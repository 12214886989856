/**
 * You can find the source of this story in the "Source Code" tab.
 * Open any of the stories other than the "docs". 📖
 * Then, show addons (click on the gear icon ⚙️ on the top right or use shortcut ⌥ a / alt a).
 * Finally, click on the "Source Code" tab.
 */
import React from "react"
import {
    Button, TabContext, Tabs, TabPanels, Item, Paragraph,
    FlexBox,
    TextField,
    Label,
    ToggleButtonGroup,


    Dialog,
    DialogActions,
    DialogContainer,
    DialogContent,
    DialogTitle,

} from "@filament/react";

import { useEffect } from "react";

import { RootState } from "../../../redux/store";

import { StorePrivalgeValue } from "../../../redux/PrivilageTypeState";

import { atomicDividerHorizontal as dividerHorizontal } from '@filament/react/styles';

import { Cross } from '@filament-icons/react';

import CREATE_USER from "../../../graphql/mutations/addUser.graphql";
import { useMutation } from "@apollo/client";
import { MfaEnabled, Status } from "../../../graphql/generated";


//import { useCreateUserMutation } from "../../../graphql/generated";



import { useSelector, useDispatch } from "react-redux";

import { useCallback, useState } from "react";
import UserModal from "./UserModal";
import UserPrevilagePreview from "./UserPrevilagePreview";








export const Default = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true)
    const [allTasks, setAllTasks] = useState<string[]>([''])
    const previlageType = useSelector((state: RootState) => state.PrivilageTypeState.privlageValue)
    const selectPrevilageValues = useSelector((state: RootState) => state.SelectPrivilageState)
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)

    const [createUser] = useMutation(CREATE_USER);

    const dismiss = useCallback(() => {
        setIsOpen(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    const privilageAndRoles = useSelector((state: RootState) => state.PrivilageTypeState.SelectedRoles)
    const Tasks = useSelector((state: RootState) => state.PrivilageTypeState.Tasks)

    const result = Object.entries(Tasks).map(([key, values]) => {
        return {
            key,
            values, // This will contain the array of values for the current key
        };
    });


    useEffect(() => {

        if (result[0].values) {

            console.log("Keys inside values:");
            const keys = Object.keys(result[0].values);
            const allValues = keys.map((key) => result[0].values[key]).flat();

            setAllTasks([...allValues])




        }

    }, [result])








    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        if (event.currentTarget.id === 'userForm') {

            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            //const formEntries = Object.fromEntries(formData.entries())

            const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;

            const email = formData.get('email') as string
            const firstName = formData.get('firstName') as string
            const lastName = formData.get('lastName') as string
            const jobTitle = formData.get('jobTitle') as string

            const department = formData.get('department') as string
            const MFA = formData.get('MFA') as MfaEnabled
            if (!emailRegex.test(email)) {

                console.log(event.currentTarget.id, "its false you known ")
                setIsEmailValid(false)

                return;
            }


            try {
                const response = await createUser({
                    variables: {
                        organization_id: "673582adeed3cccc395494ae",
                        organization_name: 'organization',
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        status: Status.Pending,
                        mfa_enabled: MFA,
                        department: department,
                        jobTitle: jobTitle,
                        privilegeType: previlageType,
                        userTasks: [...allTasks],
                        featureSelected: [...privilageAndRoles],
                        featureScope: "orgnization 1",
                        siteSelected: [selectPrevilageValues.SelectSite],
                        expiryOfPrivilege: selectPrevilageValues.ExpiryOfPrivilage,
                        expiryDate: selectPrevilageValues.Date,
                        delegatePower: selectPrevilageValues.DelegatePower,
                        numberOfUser: selectPrevilageValues.NumberOfUsers === 0 ? 1 : selectPrevilageValues.NumberOfUsers,
                    },
                });

                console.log('User created:', response.data);
            } catch (err) {
                console.error('Error creating userrrrrrrrrrrrrrrr:', err);
            }



            setIsEmailValid(true)
            dispatch(StorePrivalgeValue(''))


            setIsOpen(false)



        }

    }


    const EmailValidation = () => {

        if (isEmailValid) {
            return undefined

        }

        return "there is a error in the code"

    }



    return (
        <>
            <Button onPress={() => setIsOpen(true)} variant={'secondary'}
                style={{ fontSize: '16px', fontWeight: 'bold' }}>+ New</Button>


            {/* {isOpen && <div
                style={{
                    
                    transition: "opacity 0.5s ease-in-out", // Fade-in/out effect
                    opacity: isOpen ? 1 : 0,
                    backgroundColor:'rgb(0,0,0.5)'

                }}>
                <Card height={'100vh'}
                    style={{
                        overflowY:'scroll',
                        backgroundColor: 'white',
                        zIndex: 1000, top: 0, position: 'fixed', right: '0', left: 'auto',
                        width: '848px', borderRadius: '20px 0px 5px 20px'
                    }}>
                    <FlexBox justifyContent='space-between' alignItems={'center'} marginBottom={'22px'}>
                        <CardHeader style={{ marginLeft: '16px', marginTop: '28px' }}>

                            <Text variant={'heading-m'} >New</Text>


                        </CardHeader>
                        <Button style={{ borderRadius: '10px', marginRight: '22px', marginTop: '22px' }}
                            variant={'quiet'} onPress={() => { setIsOpen(false); setIsEmailValid(true) }}>
                            <Cross />
                        </Button>
                    </FlexBox>
                    <hr className={dividerHorizontal}></hr>

                    <CardBody style={{ padding: 0, margin: 0, }}>
                        <TabContext style={{ padding: 0, margin: 0 }}>

                            <Tabs id={'tabs'} >

                                <Item key="tab1">

                                    User

                                </Item>
                                <Item key="tab2">Site</Item>
                                <Item key="tab3">Booking</Item>
                                <Item key={'tab4'}>Organisation</Item>
                                <Item key={'tab5'}>Invite</Item>
                            </Tabs>


                            <TabPanels style={{ padding: '32px' }}>

                                <Item key="tab1" >
                                    <Text color="secondary"
                                        style={{ margin: 0 }}>*Add user in your organization using this module.</Text>

                                    <form id={'userForm'} onSubmit={onSubmit} style={{ marginTop: '24px' }}>


                                        <div
                                            style={{
                                                display: 'grid',
                                                columnGap: '0.5rem',
                                                rowGap: '1.5rem',
                                                gridTemplateColumns: '220px 1fr',
                                                alignItems: 'center',
                                            

                                            }}>

                                            <Label htmlFor="email">Email</Label>
                                            <TextField id={'email'} name={'email'}
                                                placeholder="Enter the email" inputMode={'email'} isFullWidth
                                                isRequired={true}
                                                validate={EmailValidation}
                                                errorMessages={'enter the valid email Id'}

                                                validationBehavior={'aria'}




                                            />



                                            <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                <Label htmlFor="firstName">First name</Label>
                                                <Label variant={'descriptor'}>(optional)</Label>
                                            </div>

                                            <TextField id={'firstName'} name={'firstName'}
                                                placeholder="Enter the first name" inputMode={'text'} isFullWidth />


                                            <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                <Label htmlFor="lastName">Last name</Label>
                                                <Label variant={'descriptor'}>(optional)</Label>
                                            </div>

                                            <TextField id={'lastName'} name={'lastName'}
                                                placeholder="Enter the last name" inputMode={'text'} isFullWidth />

                                            <Label htmlFor="organization">Organization</Label>
                                            <TextField id={'organization'} name={'organization'}
                                                placeholder="Enter the organization" inputMode={'text'}
                                                value={'organization'} isDisabled={true} isFullWidth />


                                            <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                <Label htmlFor="department">Department</Label>
                                                <Label variant={'descriptor'}>(optional)</Label>
                                            </div>

                                            <TextField id={'department'} name={'department'}
                                                placeholder="Enter the department" inputMode={'text'} isFullWidth />


                                            <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                <Label htmlFor="JobTitle">Job title</Label>
                                                <Label variant={'descriptor'}>(optional)</Label>
                                            </div>

                                            <TextField id={'JobTitle'} name={'JobTitle'}
                                                placeholder="Enter the Job" inputMode={'text'} isFullWidth />



                                            <Label htmlFor="MFA">MFA</Label>

                                            <ToggleButtonGroup showAsGroup isFullWidth
                                                selectionMode={'single'}
                                                defaultSelectedKeys={[MfaEnabled.Disabled]}
                                                id={'MFA'} name={'MFA'} width={'302px'}>

                                                <Item key={MfaEnabled.Disabled}>Disabled</Item>
                                                <Item key={MfaEnabled.Enabled} >Enabled</Item>


                                            </ToggleButtonGroup>

                                            <Label htmlFor={'Privileges'}>Privileges</Label>


                                            <UserModal setShow={setShow} />






                                        </div>



                                    </form>
                                    {(previlageType.length > 0&&show) && <UserPrevilagePreview />}

                                </Item>
                                <Item key="tab2">
                                    <Paragraph marginY="1rem">Tab 2 Content</Paragraph>
                                </Item>
                                <Item key="tab3">
                                    <Paragraph marginY="1rem">Tab 3 Content</Paragraph>
                                </Item>
                                <Item key="tab4">
                                    <Paragraph marginY="1rem">Tab 4 Content</Paragraph>
                                </Item>
                            </TabPanels>
                        </TabContext>
                       
                    </CardBody>



                    <FlexBox justifyContent="flex-end" marginTop={'auto'} flexDirection="column" gap={16}>
                        <hr className={dividerHorizontal}></hr>

                        <FlexBox justifyContent="flex-end">
                            <CardFooter >


                                <Button onPress={() => { setIsOpen(false); setIsEmailValid(true) }}>Cancel</Button>
                                <Button type={'submit'} variant={'primary'}
                                    onPress={() => {
                                        // Programmatically submit the form
                                        const form = document.getElementById('userForm') as HTMLFormElement;
                                        console.log('you have selected it ')
                                        if (form) {
                                            form.requestSubmit(); // HTML5 method for programmatically submitting forms
                                        }
                                    }}
                                    isDisabled={previlageType.length === 0}
                                >Save</Button>


                            </CardFooter>
                        </FlexBox>
                    </FlexBox>
                </Card>
            </div>} */}

            <div
                style={{
                    transition: "opacity 0.5s ease-in-out", // Fade-in/out effect
                    opacity: isOpen ? 1 : 0,
                    background: 'white'
                }}
            >

                <DialogContainer onDismiss={dismiss} isOpen={isOpen} style={{ margin: '0px' }} height={'100vh'} >
                    <Dialog
                        id='Drawer'
                        style={{
                            height: "100vh",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: "auto",
                            // margin: 0,
                            position: 'fixed',
                            marginTop: 0,
                            marginBottom: 0,
                            borderRadius: "10px 0 0 10px",
                            width: isOpen ? "848px" : "0px", // Animate width from 0 to 848px
                            // overflow: "hidden", // Prevent content from spilling out during animation
                            transition: "width 0.5s ease-in-out", // Smooth width transition
                            boxShadow: isOpen ? "-2px 0 8px rgba(0, 0, 0, 0.2)" : "none", // Shadow only when open
                        }}
                    >

                        <FlexBox justifyContent='space-between' alignItems={'center'} >
                            <DialogTitle style={{ marginLeft: '16px', marginTop: '28px' }}>

                                New


                            </DialogTitle>
                            <Button style={{ borderRadius: '10px', marginRight: '22px', marginTop: '22px' }}
                                variant={'quiet'} onPress={() => { setIsOpen(false); setIsEmailValid(true) }}>
                                <Cross />
                            </Button>


                        </FlexBox>

                        <hr className={dividerHorizontal}></hr>


                        <DialogContent height={'100vh'} style={{ padding: 0 }}>

                            <TabContext style={{ padding: 0 }}>

                                <Tabs id="tabs">

                                    <Item key="tab1">User</Item>
                                    <Item key="tab2">Site</Item>
                                    <Item key="tab3">Booking</Item>
                                    <Item key={'tab4'}>Organisation</Item>
                                    <Item key={'tab5'}>Invite</Item>
                                </Tabs>


                                <TabPanels style={{ padding: '32px' }}>

                                    <Item key="tab1" >

                                        <form id={'userForm'} onSubmit={onSubmit}>


                                            <div
                                                style={{
                                                    display: 'grid',
                                                    columnGap: '0.5rem',
                                                    rowGap: '1.5rem',
                                                    gridTemplateColumns: '220px 1fr',
                                                    alignItems: 'center'

                                                }}>

                                                <Label htmlFor="email">Email</Label>
                                                <TextField id={'email'} name={'email'}
                                                    placeholder="Enter the email" inputMode={'email'} isFullWidth
                                                    isRequired={true}
                                                    validate={EmailValidation}
                                                    errorMessages={'enter the valid email Id'}

                                                    validationBehavior={'aria'}




                                                />



                                                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                    <Label htmlFor="firstName">First name</Label>
                                                    <Label variant={'descriptor'}>(optional)</Label>
                                                </div>

                                                <TextField id={'firstName'} name={'firstName'}
                                                    placeholder="Enter the first name" inputMode={'text'} isFullWidth />


                                                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                    <Label htmlFor="lastName">Last name</Label>
                                                    <Label variant={'descriptor'}>(optional)</Label>
                                                </div>

                                                <TextField id={'lastName'} name={'lastName'}
                                                    placeholder="Enter the last name" inputMode={'text'} isFullWidth />

                                                <Label htmlFor="organization">Organization</Label>
                                                <TextField id={'organization'} name={'organization'}
                                                    placeholder="Enter the organization" inputMode={'text'}
                                                    value={'organization'} isDisabled={true} isFullWidth />


                                                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                    <Label htmlFor="department">Department</Label>
                                                    <Label variant={'descriptor'}>(optional)</Label>
                                                </div>



                                                <TextField id={'department'} name={'department'}
                                                    placeholder="Enter the department" inputMode={'text'} isFullWidth />

                                                <div style={{ display: 'flex', height: 'fit-content', gap: '0.5rem' }}>
                                                    <Label htmlFor="JobTitle">Job title</Label>
                                                    <Label variant={'descriptor'}>(optional)</Label>
                                                </div>

                                                <TextField id={'JobTitle'} name={'JobTitle'}
                                                    placeholder="Enter the Job" inputMode={'text'} isFullWidth />

                                                <Label htmlFor="MFA">MFA</Label>

                                                <ToggleButtonGroup showAsGroup isFullWidth
                                                    selectionMode={'single'}
                                                    defaultSelectedKeys={[MfaEnabled.Disabled]}
                                                    id={'MFA'} name={'MFA'} width={'302px'}>

                                                    <Item key={MfaEnabled.Disabled}>Disabled</Item>
                                                    <Item key={MfaEnabled.Enabled} >Enabled</Item>


                                                </ToggleButtonGroup>

                                                <Label htmlFor={'Privileges'}>Privileges</Label>


                                                <UserModal setShow={setShow} />






                                            </div>



                                        </form>
                                        {(previlageType.length > 0 && show) && <UserPrevilagePreview />}

                                    </Item>
                                    <Item key="tab2">
                                        <Paragraph marginY="1rem">Tab 2 Content</Paragraph>
                                    </Item>
                                    <Item key="tab3">
                                        <Paragraph marginY="1rem">Tab 3 Content</Paragraph>
                                    </Item>
                                    <Item key="tab4">
                                        <Paragraph marginY="1rem">Tab 4 Content</Paragraph>
                                    </Item>
                                </TabPanels>
                            </TabContext>
                           
                        </DialogContent>


                        <hr className={dividerHorizontal}></hr>
                        <DialogActions>
                            <FlexBox justifyContent={'flex-end'} padding={10} gap={12} >
                                <Button onPress={() => { setIsOpen(false); setIsEmailValid(true) }}>Cancel</Button>
                                <Button type={'submit'} variant={'primary'}
                                    onPress={() => {
                                        // Programmatically submit the form
                                        const form = document.getElementById('userForm') as HTMLFormElement;
                                        console.log('you have selected it ')
                                        if (form) {
                                            form.requestSubmit(); // HTML5 method for programmatically submitting forms
                                        }
                                    }}
                                    isDisabled={previlageType.length === 0}
                                >Save</Button>
                            </FlexBox>
                        </DialogActions>

                    </Dialog>

                </DialogContainer>

            </div>

        </>

    );
};