
import {
    Dialog, DialogContainer, Button, DialogContent,
    DialogTitle, Link, DialogActions, Label, RadioButton, RadioGroup,
    FlexBox, Text
} from "@filament/react"
import React, { useCallback, useState } from "react"

import { atomicDividerHorizontal as dividerHorizontal } from '@filament/react/styles';
import { Cross } from '@filament-icons/react';

import { StorePrivalgeValue } from "../../../redux/PrivilageTypeState";


import SelectPrivilageModal from "./SelectPrevilageModal";
import UserPrevilagePreview from "./UserPrevilagePreview";
import { useDispatch } from "react-redux";
import UserPrevilageAndRoles from "./UserPrevilageAndRoles";
import SelectedUserTasks from "./SelectedUserTask";


const UserModal = ({setShow}:{setShow:React.Dispatch<React.SetStateAction<boolean>>}) => {


    const [isOpen, setIsOpen] = useState(false)
    const [radioValue, setRadioValue] = useState<string>("")
    const [index, setIndex] = useState(-1)
    const dispatch = useDispatch()

    const AllFeatures: JSX.Element[] = [
        <SelectPrivilageModal key="1" />,
        <UserPrevilagePreview key="2" />,


    ];

    const SelectFeatures: JSX.Element[] = [
        <UserPrevilageAndRoles key="1" />,
        <SelectPrivilageModal key="2" />,
        <UserPrevilagePreview key="3" />,
    ];


    const SelectTasks: JSX.Element[] = [
        <SelectedUserTasks key="1" />,
        <SelectPrivilageModal key="2" />,
        <UserPrevilagePreview key="3" />,
    ];






    const dismiss = useCallback(() => {
        setIsOpen(false)
    }, [])


    const loadFlow = (radioValue: string) => {
        if (radioValue === "Select Tasks") {
            return SelectTasks
        }
        else if (radioValue === "Select Features") {
            return SelectFeatures
        }
        else {
            return AllFeatures
        }


    }

    const flow = loadFlow(radioValue)



    const onNextDisable = (): boolean => {
        if (!radioValue.length) {
            return true
        }



        return false

    }

    const onCancle = () => {
        setRadioValue("")
        setIndex(-1)
        setIsOpen(false)

    }

    const length = flow.length - 1

    const handleNextClick = () => {

        //const formm = document.getElementById('ModalForm') as HTMLFormElement;



        if (index >= length) {
            setIndex(-1)
            setShow(true)
            setIsOpen(false)
        }
        else {
            if (index === -1) {
                dispatch(StorePrivalgeValue(radioValue))
            }
            setIndex((prev) => prev + 1)

        }






    }

    const onBack = () => {

        setIndex((prev) => prev - 1)
    }






    return (
        <>
            <Link onPress={() => setIsOpen(true)}>
                Select privileges and roles
            </Link>
            <DialogContainer onDismiss={dismiss} isOpen={isOpen}>
                <Dialog style={{ height: '800px', width: '1032px' }}>

                    <DialogTitle marginY={'10px'}
                        style={{ fontSize: '24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>


                        <Text variant="heading-xs">
                            Privileges and Roles
                        </Text>


                        <Button variant={'quiet'} style={{ borderRadius: '10px' }} onPress={onCancle}><Cross /></Button>


                    </DialogTitle>
                    <hr className={dividerHorizontal} ></hr>
                    <DialogContent style={{
                        paddingTop:'24px',
                        paddingBottom:'24px',
                        paddingRight: `${index === flow.length - 1 ? '0px' : '24px'}`,
                        paddingLeft: `${index === flow.length - 1 ? '0px' : '24px'}`,

                        // paddingTop:`${index === flow.length - 1 ? '24pxx' : ''}`,



                    }}  >
                        {index === flow.length - 1 && <Text variant={'heading-xs'} marginX={'20px'}  >
                            Privilege Preview</Text>}

                        {index === -1 &&
                            <>
                                <Label htmlFor="privilageType"
                                    style={{ fontSize: '20px', fontWeight: 'bold' }}>Select Privilege Type</Label>
                                <div style={{ marginTop: '24px' }}>
                                    <RadioGroup orientation={'vertical'} id={'privilageType'} name={'privilageType'}
                                        onChange={(event: string) => setRadioValue(event)}
                                    >
                                        <RadioButton value={'All Features'}>Full Access : All Features</RadioButton>
                                        <RadioButton value={'Select Features'}>
                                            Full Access : Select Features</RadioButton>
                                        <RadioButton value={'Select Tasks'}>
                                            Restricted Access : Select Tasks</RadioButton>

                                    </RadioGroup>
                                </div>
                            </>
                        }

                        {flow[index]}
                        {index === length && <FlexBox justifyContent="center" paddingTop={92}>
                            <Button variant={'primary'} isDisabled={true}> + Add More Privilege</Button>
                        </FlexBox>}

                    </DialogContent>

                    <hr className={dividerHorizontal}></hr>

                    <DialogActions>
                        {index === -1 ?
                            <Button variant={'secondary'} onPress={onCancle}>Cancel</Button>
                            :

                            <Button variant={'secondary'} onPress={onBack}>Back</Button>
                        }

                        {index === length ?
                            <Button type={'button'} variant={'primary'}
                                onPress={handleNextClick}
                            >
                                Save
                            </Button>
                            :
                            <Button type={'button'} variant={'primary'}
                                onPress={handleNextClick}
                                isDisabled={onNextDisable()}
                            >Next</Button>


                        }



                    </DialogActions>
                </Dialog>




            </DialogContainer>



        </>
    )

}


export default UserModal