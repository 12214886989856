
import { Checkbox, CheckboxGroup, FlexBox, Label,Text } from "@filament/react"


import React, { useEffect } from "react"
import { useState } from "react"

import { StoreTasks } from "../../../redux/PrivilageTypeState"
import { useDispatch} from "react-redux"

import { useQuery } from "@apollo/client"


import GET_TASKS_LIST from "../../../graphql/queries/getTasks.graphgl"

export interface selectedUserValues {

    selectUser: {
        [taskType: string]: string[]; // taskType is the key, and its value is an array of strings
    };
}

interface Tasks {
    taskType: string,
    tasks: string[],
    __typename: string,
}


const SelectedUserTasks = () => {

    const [value, setValue] = useState<selectedUserValues>({
        selectUser: {

        }
    })

    const dispatch=useDispatch()


    useEffect(()=>{
        dispatch(StoreTasks(value))

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])

    //need to remove these
    

    const { loading, error, data } = useQuery(GET_TASKS_LIST, {
        variables: {
            id: "675147fc9d15fe7d3a51dbdb",
            listType: "userTasksList"
        }
    })

    if (loading) {
        return <p>....loading</p>
    }
    if (error) {
        return <p>its an error</p>
    }









    return <FlexBox flexDirection={'column'} gap={24}>
        <Text variant={'heading-xs'}>Select User Tasks</Text>

        {data ? <>
            {data.roleTemplateLists.userTasksList.map((value: Tasks, index: number) => (

                <FlexBox key={index} flexDirection={'column'} gap={6} id={'' + index}>
                    <Label htmlFor={value.taskType} style={{ fontWeight: 'bold' }} >{value.taskType}</Label>
                    <CheckboxGroup id={value.taskType} name={value.taskType} onChange={(event:string[]) =>
                        setValue((prev: selectedUserValues) => ({
                            ...prev,
                            selectUser: {
                                ...prev.selectUser,
                                [value.taskType]: [...event],

                            }
                        }))
                    }


                    >
                        {value.tasks.map((value: string, index: number) => (
                            <Checkbox value={value} key={index}>{value}</Checkbox>
                        ))}

                    </CheckboxGroup>


                </FlexBox>


            ))}
        </> : <p>loading....</p>

        }




        {/* <FlexBox flexDirection={'column'} gap={6}>
            <Label htmlFor={'DeviceClaim'} style={{ fontWeight: 'bold' }} >Device Claim</Label>

            <CheckboxGroup id={'DeviceClaim'} name='DeviceClaim' onChange={(event) =>
                setValue((prev: selectedUserValues) => ({
                    ...prev, // Spread the existing state to keep other properties
                    DeviceClaim: [...event], // Update the specific property
                }))
            }


            >
                {
                    DeviceClaim.map((value) => (
                        <Checkbox value={value} key={value}>{value}</Checkbox>
                    ))
                }

            </CheckboxGroup>

        </FlexBox>



        <FlexBox flexDirection={'column'} gap={6}>
            <Label htmlFor={'DeviceManagement'} style={{ fontWeight: 'bold' }}>Device Management</Label>

            <CheckboxGroup id={'DeviceManagement'} name='DeviceManagement' onChange={(event) =>
                setValue((prev: selectedUserValues) => ({
                    ...prev, // Spread the existing state to keep other properties
                    DeviceManagement: [...event], // Update the specific property
                }))
            }


            >
                {
                    DeviceManagement.map((value) => (
                        <Checkbox value={value.label} key={value.label}>{value.label}</Checkbox>
                    ))
                }

            </CheckboxGroup>

        </FlexBox>


        <FlexBox flexDirection={'column'} gap={6}>
            <Label htmlFor={'ContentManagement'} style={{ fontWeight: 'bold' }}>Content Management</Label>

            <CheckboxGroup id={'ContentManagement'} name='ContentManagement' onChange={(event) =>
                setValue((prev: selectedUserValues) => ({
                    ...prev, // Spread the existing state to keep other properties
                    ContentManagement: [...event], // Update the specific property
                }))
            }

            >
                {
                    ContentManagement.map((value) => (
                        <Checkbox value={value.label} key={value.label}>{value.label}</Checkbox>
                    ))
                }

            </CheckboxGroup>

        </FlexBox>


        <FlexBox flexDirection={'column'} gap={6}>
            <Label htmlFor={'SiteManagement'} style={{ fontWeight: 'bold' }}>Site Management</Label>

            <CheckboxGroup id={'SiteManagement'} name='SiteManagement' onChange={(event) =>
                setValue((prev: selectedUserValues) => ({
                    ...prev, // Spread the existing state to keep other properties
                    SiteManagement: [...event], // Update the specific property
                }))
            }

            >
                {
                    SiteManagement.map((value) => (
                        <Checkbox value={value.label} key={value.label}>{value.label}</Checkbox>
                    ))
                }

            </CheckboxGroup>

        </FlexBox>



        <FlexBox flexDirection={'column'} gap={6}>
            <Label htmlFor={'UserManagement'} style={{ fontWeight: 'bold' }}>User Management</Label>

            <CheckboxGroup id={'UserManagement'} name='UserManagement' onChange={(event) =>
                setValue((prev: selectedUserValues) => ({
                    ...prev, // Spread the existing state to keep other properties
                    UserManagement: [...event], // Update the specific property
                }))
            }

            >
                {
                    UserManagement.map((value) => (
                        <Checkbox value={value.label} key={value.label}>{value.label}</Checkbox>
                    ))
                }

            </CheckboxGroup>

        </FlexBox> */}




    </FlexBox>
}


export default SelectedUserTasks