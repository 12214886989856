/* eslint-disable max-len */
export default function DateDisplay({ date }: { date: string }) {

    const inputDate = new Date(date);

    // Adjust the time to 21:00
    inputDate.setHours(21, 0, 0, 0);

    // Change the year to 2025
    inputDate.setFullYear(2024);

    // Format as "HH:mm, dd/MM/yyyy"
    // const formattedDate = inputDate.toLocaleString("en-GB", {
    //     hour: '2-digit',
    //     minute: '2-digit',
    //     day: '2-digit',
    //     month: '2-digit',
    //     year: 'numeric'
    // });
    const formattedDate = `${inputDate.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" })}, ${inputDate.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" })}`;

    return formattedDate
}