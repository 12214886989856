

import { Label, RadioButton, Text, TextField ,RadioGroup,DatePicker, FlexBox,} from "@filament/react"
import React, { useEffect, useState } from "react"

import { StoreSelectPrivilage } from "../../../redux/SelectPrivilageState"
import { useDispatch } from "react-redux"
import useDebounce from "./Debounce"

export interface privalgeValues{
    ScopeOfPrivilege:string,
    SelectSite:string,
    ExpiryOfPrivilage:'Infinite'|'Specify Period',
    Date:Date,
    DelegatePower:"Within-Organization"|"External-Organization"|"None",
    NumberOfUsers:number

}



const SelectPrivilageModal=()=>{

    const [value,setValue]=useState<privalgeValues>({
        ScopeOfPrivilege:"organization",
        SelectSite:"All",
        ExpiryOfPrivilage:'Infinite',
        Date:new Date(),
        DelegatePower:'None',
        NumberOfUsers:0
    })

    

    

    const dispatch=useDispatch()

    const debouncedScopeOfPrivilage=useDebounce(value,500)



    useEffect(()=>{
        // console.log("debounce value",debouncedScopeOfPrivilage)

        if(debouncedScopeOfPrivilage){
            dispatch(StoreSelectPrivilage(value))
        }
        
        

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[debouncedScopeOfPrivilage])



    const [timeDifference, setTimeDifference] = useState<string | null>(null);

    const handleDateChange = (selectedDate: Date) => {

        const currentDate = Date.now(); // Current date and time
        const diffDate = (selectedDate.getTime() - currentDate);
        setValue((prev) => ({
            ...prev,
            Date:selectedDate
        }))
        
      
        if (diffDate >=0) {
            // Convert milliseconds to years, months, days, hours, minutes, seconds
            const years = Math.floor(diffDate / (1000 * 60 * 60 * 24 * 365));
            const remainingAfterYears = diffDate % (1000 * 60 * 60 * 24 * 365);
          
            const months = Math.floor(remainingAfterYears / (1000 * 60 * 60 * 24 * 30));
            const remainingAfterMonths = remainingAfterYears % (1000 * 60 * 60 * 24 * 30);
          
            const days = Math.floor(remainingAfterMonths / (1000 * 60 * 60 * 24));
            const remainingAfterDays = remainingAfterMonths % (1000 * 60 * 60 * 24);
          
            const hours = Math.floor(remainingAfterDays / (1000 * 60 * 60));
            const remainingAfterHours = remainingAfterDays % (1000 * 60 * 60);
          
            const minutes = Math.floor(remainingAfterHours / (1000 * 60));
            const seconds = Math.floor((remainingAfterHours % (1000 * 60)) / 1000);

            console.log(hours,minutes,seconds,"timing slectPrivilage")
          
            setTimeDifference(
                `${years===0?'':years+' years'}  
                ${months===0?'':months+' months'}  ${days===0?'':days+' day'}  ${hours===0?'':hours+' hours'}  `
            );
        } else {
            setTimeDifference(null);
        }

        
    };


    
    return(
        <>
            <div style={{display:'flex',flexDirection:'column',gap:'24px'}}>
                <Text variant={'heading-xs'}>Select Privilege Scope</Text>
                <div style={{display:'flex',flexDirection:'column',gap:'24px'}}>

                    <FlexBox flexDirection={'column'} gap={16}>
                        <Label htmlFor="organization">Scope of Privilege</Label>
                        <TextField id={'organization'} name={'organization'} 
                            placeholder="enter the organization" inputMode={'text'} 
                            value={value.ScopeOfPrivilege} isDisabled={true} isFullWidth/>
                    </FlexBox>

                    <FlexBox flexDirection={'column'} gap={16}>
                        <Label htmlFor="Select Site">Select Site</Label>
                        <TextField id={'Select Site'} name={'Select Site'} inputMode={'text'} defaultValue={'All'}
                            value={value.SelectSite} isDisabled
                            isFullWidth/>
                    </FlexBox>


                    <FlexBox flexDirection={'column'} gap={16}>
                        <Label htmlFor="ExpiryPrivilege">Expiry of Privilege</Label>

                        <RadioGroup orientation="horizontal" id={'ExpiryPrivilege'} name="ExpiryPrivilege" 
                            onChange={(event:'Infinite'|'Specify Period') => 
                                setValue((prev) => ({
                                    ...prev,
                                    ExpiryOfPrivilage:event as 'Infinite'|'Specify Period'
                                }))
                            }
                            defaultValue={'Infinite'}
                        >
                            <RadioButton value="Infinite">Infinite</RadioButton>
                            <RadioButton value="Specify Period">Specify Period</RadioButton>
                        
                        </RadioGroup>
                    </FlexBox>

                   

                    {value.ExpiryOfPrivilage==='Specify Period'
               &&(<div>
                   <Label htmlFor="SelectDate">Select the date</Label>
                   <div>
                       <DatePicker granularity={'minute'} minValue={new Date()} 
                           id={'SelectDate'} name={'SelectDate'}
                           onChange={(date:Date|null)=>{

                               if(date){
                                   handleDateChange(new Date(date))
                               }
                    
                           }} />
                   </div>
                       
               </div>)}

                    {(timeDifference&&(value.ExpiryOfPrivilage==='Specify Period'))&&(
                        <Text color={'secondary'}>Expiry in  {timeDifference}</Text>
                    )}

                



                    <FlexBox flexDirection={'column'} gap={16}>
                        <Label htmlFor={'DelegatePower'}>Delegate Power</Label>

                        <RadioGroup orientation="horizontal" id={'DelegatePower'} name={'DelegatePower'}
                            onChange={(event:"Within-Organization"|"External-Organization"|"None")=>{setValue((prev)=>({
                                ...prev,
                                DelegatePower:event as "Within-Organization"|"External-Organization"|"None"
                            }));
                            if(event==='Within-Organization'){
                                setValue((prev) => ({
                                    ...prev,
                                    NumberOfUsers:3
                                }))
                            }
                            else{
                                setValue((prev) => ({
                                    ...prev,
                                    NumberOfUsers:0
                                }))
                            }
                    
                            }}
                            defaultValue={'None'}
                        >
                            <RadioButton value='Within-Organization'>Within Organization</RadioButton>
                            <RadioButton value='External-Organization'>External Organization</RadioButton>
                            <RadioButton value="None">None</RadioButton>
                        </RadioGroup>
                        
                    </FlexBox>
                    {(value.DelegatePower!=="None")&&
                    <div>
                        <Label htmlFor="NumberOfUsers">Number of Users</Label>
                        <div>
                            <TextField inputMode={'decimal'} id={'NumberOfUsers'} name={'NumberOfUsers'} 

                                value={''+value.NumberOfUsers} 
                                
                                onChange={(event:string) => 
                                    setValue((prev) => ({
                                        ...prev,
                                        NumberOfUsers:Number(event)
                                    }))
                                }
                                
                            />
                        </div>
                    </div>}
                  

                </div>

              


                

               
               

               
                
            </div>
        </>
    )
}

export default SelectPrivilageModal