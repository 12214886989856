/* eslint-disable max-len */
// /* eslint-disable max-len */
// /* eslint-disable react/react-in-jsx-scope */
// import { Box, } from "@chakra-ui/react";

// import { FlexBox, Text, TabContext, Item, Paragraph, Tabs, TabPanels,} from "@filament/react";
// import { Search, Filter, FilterSliderVertical, Settings } from '@filament-icons/react';
// import { atomicIconBold as iconBold,atomicBorderBottom as borderBottom } from '@filament/react/styles';

// import RolesAndPrivileges from "../RolesAndPrivileges/Table";
// import UserTable from "../AllUsers/Table/UserTable";

// export default function TopBar() {
//     return (
//         <>
//             <div >
//                 <div >
//                     <FlexBox height='56px' position="fixed" top='112px' left='72px' zIndex={1000} justifyContent="space-between" width={`calc(100% - 72px)`} >
//                         <Text style={{paddingLeft: '24px' , paddingTop: '16px'}} variant="heading-xs">Organisation</Text>
//                         <FlexBox alignItems="center" gap={20} >
//                             <Search className={iconBold}/>
//                             <Filter className={iconBold}/>
//                             <FilterSliderVertical className={iconBold}/>
//                             <FlexBox alignItems="center" gap={4}>
//                                 <Settings className={iconBold}/>
//                                 <Text variant="body-m" weight="bold" style={{paddingRight: '24px'}}>Customise</Text>
//                             </FlexBox>
//                         </FlexBox>
//                     </FlexBox>
//                 </div>
//                 <Box  position="fixed" top='160px' left='72px' zIndex={1000} width={`calc(100% - 72px)`}>
//                     <TabContext>
//                         <Tabs noBorder className={borderBottom} id="tabs" style={{ paddingBottom: '0rem'}} >
//                             <Item key="tab1"><span color="secondary">All Organisations</span></Item>
//                             <Item key="tab2"><span color="secondary">All Users</span></Item>
//                             <Item key="tab3"><span color="secondary">Roles and Privileges</span></Item>
//                         </Tabs>
//                         <TabPanels>
//                             <Item key="tab1">
//                                 {/* remove margin start according to the contents coming in this page */}
//                                 <Paragraph marginY="1rem" marginStart='1rem'>All Organisations</Paragraph>
//                             </Item>
//                             <Item key="tab2">
//                                 <Paragraph marginY="1rem" marginStart='0.04rem'><UserTable /></Paragraph>
//                             </Item>
//                             <Item key="tab3">
//                                 <Paragraph marginY="1rem" marginStart='0.04rem'><RolesAndPrivileges /></Paragraph>
//                             </Item>
//                         </TabPanels>
//                     </TabContext>
//                 </Box>
//             </div>
//         </>
//     );
// }

import React from 'react';
import { FlexBox, Text, TabContext, Item, Paragraph, Tabs, TabPanels } from '@filament/react';
import { Search, Filter, FilterSliderVertical, Settings } from '@filament-icons/react';
import { atomicIconBold as iconBold, atomicBorderBottom as borderBottom } from '@filament/react/styles';

// const UserTable = lazy(() => import('../AllUsers/Table/UserTable'));
// const RolesAndPrivileges = lazy(() => import('../RolesAndPrivileges/Table'));

import RolesAndPrivileges from "../RolesAndPrivileges/Table";
import UserTable from "../AllUsers/Table/UserTable";

export default function TopBar() {
    return (
        <>
            <FlexBox height="56px" position="fixed" top="112px" left="72px" zIndex={1000} justifyContent="space-between" width="calc(100% - 72px)">
                <Text style={{ paddingLeft: '24px', paddingTop: '16px' }} variant="heading-xs">Organisation</Text>
                <FlexBox alignItems="center" gap={20}>
                    <Search className={iconBold} />
                    <Filter className={iconBold} />
                    <FilterSliderVertical className={iconBold} />
                    <FlexBox alignItems="center" gap={4}>
                        <Settings className={iconBold} />
                        <Text variant="body-m" weight="bold" style={{ paddingRight: '24px' }}>Customise</Text>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox style={{ flexDirection: 'column' }} position="fixed" top="160px" left="72px" zIndex={1000} width="calc(100% - 72px)">
                <TabContext>
                    <Tabs noBorder className={borderBottom} id="tabs" style={{ paddingBottom: '0rem' }}>
                        <Item key="tab1"><span color="secondary">All Organisations</span></Item>
                        <Item key="tab2"><span color="secondary">All Users</span></Item>
                        <Item key="tab3"><span color="secondary">Roles and Privileges</span></Item>
                    </Tabs>
                    <TabPanels>
                        <Item key="tab1">
                            <Paragraph marginY="1rem" marginStart="1rem">All Organisations</Paragraph>
                        </Item>
                        <Item key="tab2">
                            {/* <Suspense fallback={<div>Loading...</div>}> */}
                            <Paragraph marginY="1rem" marginStart="0.04rem"><UserTable /></Paragraph>
                            {/* </Suspense> */}
                        </Item>
                        <Item key="tab3">
                            {/* <Suspense fallback={<div>Loading...</div>}> */}
                            <Paragraph marginY="1rem" marginStart="0.04rem"><RolesAndPrivileges /></Paragraph>
                            {/* </Suspense> */}
                        </Item>
                    </TabPanels>
                </TabContext>
            </FlexBox>
        </>
    );
}
