import { gql } from "@apollo/client";

const CREATE_USER = gql`
  mutation CreateUser(
    $organization_id: String!
    $organization_name: String!
    $firstName: String
    $email: String!
    $lastName: String
    $status: status!
    $mfa_enabled: MfaEnabled!
    $department: String
    $jobTitle: String
    $privilegeType: String
    $userTasks:[String]
    $featureSelected: [FeatureEnum]
    $featureScope: String
    $siteSelected: [String]
    $expiryOfPrivilege: String
    $expiryDate: String
    $delegatePower: String
    $numberOfUser: Int
  ) {
    orgcreateUser(
        organization_id: $organization_id
        organization_name: $organization_name
        firstName: $firstName
        email: $email
        lastName: $lastName
        status: $status
        mfa_enabled: $mfa_enabled
        department: $department
        jobTitle: $jobTitle
        privilegeType: $privilegeType
        userTasks:$userTasks
        featureSelected: $featureSelected
        featureScope: $featureScope
        siteSelected: $siteSelected
        expiryOfPrivilege: $expiryOfPrivilege
        expiryDate: $expiryDate
        delegatePower: $delegatePower
        numberOfUser: $numberOfUser

    ) {
      _id
    }
  }
`;

export default CREATE_USER;