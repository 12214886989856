import React from "react";
import {
    DataGrid,
    Table,
    TableHeader,
    TableBody,
    Column,
    Row,
    Cell,
    Checkbox,
    FlexBox,
    Text,
} from "@filament/react";
import { useGetRoleTemplateQuery } from "../../../../graphql/generated";
import { useGetRoleTemplateListsQuery } from "../../../../graphql/generated"; // New API hook
import { atomicBorderBottom as borderBottom } from '@filament/react/styles';
import { atomicIconSmall as iconSmall } from '@filament/react/styles';
import { ChevronUpDown } from '@filament-icons/react';
import Loading from "../../../../components/Loading";

const RolePrivilegesTable = () => {
    const id = "675147fc9d15fe7d3a51dbdb";
    const listType = "privilegeList"; // Corrected the listType to a valid value

    const { data, loading, error } = useGetRoleTemplateQuery({
        variables: { id },
    });

    const {
        data: listData,
        loading: listLoading,
        error: listError,
    } = useGetRoleTemplateListsQuery({
        variables: { id, listType },
    });

    if (loading || listLoading) return <Loading />;
    if (error) return <p>Error: {error.message}</p>;
    if (listError) return <p>Error: {listError.message}</p>;

    const roleTemplate = data?.roleTemplate;
    const privilegeHeaders = listData?.roleTemplateLists?.privilegeList || [];

    if (!roleTemplate || !roleTemplate.roles) return <p>No data available.</p>;

    const getPrivilegeArray = (privileges: (string | null)[] | null | undefined) =>
        privilegeHeaders.map((header) =>
            (privileges || []).includes(header)
        );

    function formatRoleName(roleName: string) {
        return roleName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

    function formatHeader(header: string) {
        return header
            .split('_')
            .slice(0, 2)
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

    let prevRoleType: string | null | undefined = null;

    return (
        <DataGrid>
            <div className={borderBottom}>
                <FlexBox style={{ paddingLeft: '20px' }}>
                    <Text
                        width={432}
                        style={{
                            height: '48px',
                            color: "#566676",
                            paddingLeft: '12px',
                            paddingTop: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                        }}
                    >
                        Roles <ChevronUpDown className={iconSmall} />
                    </Text>
                    <Text
                        style={{ height: '48px', color: "#566676", paddingTop: '14px', paddingLeft: '12px' }}
                    >
                        Privileges
                    </Text>
                </FlexBox>
            </div>
            <div className="scrollable-element" style={{ height: "calc(100% - 825px)", overflow: "auto" }}>
                <Table width="100%" hasStickyHeader>
                    <TableHeader>
                        <Column key="roleTypeAndName" width={432}></Column>
                        {privilegeHeaders.map((header, index) => (
                            <Column
                                style={{ paddingLeft: '19px' }}
                                key={`privilegeHeader-${index}`}
                                width={233}
                            >
                                {formatHeader(header)}
                            </Column>
                        ))}
                    </TableHeader>

                    <TableBody>
                        {roleTemplate.roles.reduce((acc, role, roleIndex) => {
                            const isNewRoleType = prevRoleType !== role?.roleType;

                            if (isNewRoleType) {
                                acc.push(
                                    <Row key={`header-${role?.roleType}`}>
                                        <Cell style={{ paddingLeft: '32px', fontWeight: 'bold' }}>
                                            {role?.roleType}
                                        </Cell>
                                        {privilegeHeaders.map((_, privilegeIndex) => (
                                            <Cell key={`empty-header-${roleIndex}-${privilegeIndex}`} />
                                        ))}
                                    </Row>
                                );
                            }

                            acc.push(
                                <Row key={`role-${roleIndex}`}>
                                    <Cell style={{ paddingLeft: '32px' }}>{formatRoleName(role.roleName)}</Cell>
                                    {getPrivilegeArray(role.privileges).map((privilege, privilegeIndex) => (
                                        <Cell
                                            style={{ paddingLeft: '32px' }}
                                            key={`privilege-${roleIndex}-${privilegeIndex}`}
                                        >
                                            <Checkbox isReadOnly isSelected={privilege} />
                                        </Cell>
                                    ))}
                                </Row>
                            );

                            prevRoleType = role?.roleType;

                            return acc;
                        }, [])}
                    </TableBody>
                </Table>
            </div>
        </DataGrid>
    );
};

export default RolePrivilegesTable;
