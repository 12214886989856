// src/ApolloProvider.tsx (or .js)
import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { GlobalProvider } from '../CommonMethods/GlobalContext';
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "../redux/store"
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
const client = new ApolloClient({
    link: new HttpLink({
        uri: 'https://api.wave-htv-dev.tpvai.com/graphql', // Replace with your API URL
    }),
    cache: new InMemoryCache(),
});

const AppApolloProvider = ({ children }: { children: React.ReactNode }) => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>   
                <ChakraProvider>
                    <GlobalProvider>
                        <ApolloProvider client={client}>
                            {children}
                        </ApolloProvider>
                    </GlobalProvider>
                </ChakraProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>
  
);

export default AppApolloProvider;