/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import * as styles from './styles/styles.css'
import { CautionISO, OperatorsManualISO } from "@filament-icons/react";
import { Button, Card, Link, Spinner, Text } from "@filament/react";
import {
    atomicIsFullWidth as atomicFullWidth,
    atomicIconLarge as iconLarge,
} from '@filament/react/styles';

import auth0 from "auth0-js"
import { useVarDispatch } from "../../redux/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { handleLoggedInState } from "../../redux/persistedStates";

export const auth = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH_DOMAIN !, // e.g., dev-xyz.us.auth0.com
    clientID: process.env.REACT_APP_CLIENT_ID !,   // Found in your Auth0 application settings
    scope: 'openid profile',
    responseType: 'id_token'
});

export type ModeTypes = 'passwordUpdationSuccess'|'LoggingIn'


export const loginWithRedirect = (): void => {
    const redirectUri = process.env.REACT_APP_STAGE === 'dev' 
        ? 'https://wave-htv-dev.tpvai.com' 
        : process.env.REACT_APP_STAGE === 'qa'?'https://wave-htv-qa.tpvai.com': 'http://localhost:3000';

    auth.authorize({
        responseType: 'id_token',
        redirectUri:redirectUri
    });

};
export default function WelcomePage(){
    const [mode, setMode] = useState<ModeTypes>('passwordUpdationSuccess');
    const location = useLocation()
    const dispatch = useVarDispatch()
    const navigate = useNavigate()

    const isModeType = (value: string): value is ModeTypes => {
        const validModes: ModeTypes[] = [
            'passwordUpdationSuccess',
            'LoggingIn',
        ];
        return validModes.includes(value as ModeTypes);
        
    };


    const searchParams = new URLSearchParams(window.location.search)

    const getQueryParam = (key: string): string |null=> {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(key); // Returns the value of the key or null if not found
    }
    const updateQueryParam = (key: string, value: string) => {
        const url = new URL(window.location.href);
        if(isModeType(value)){
            url.searchParams.set(key, value);
        }else{
            // do nothing
        }
        // Update the browser's URL without reloading the page
        window.history.pushState({}, '', url.toString());
    }
    const validate = () => {
        setMode('LoggingIn')
        setTimeout(()=>{
            dispatch(handleLoggedInState(true))
            navigate('/')
        },3000)
    };

    const getTitle = () => {
        switch (mode) {
        case 'passwordUpdationSuccess':
            return 'Password is updated';
        case 'LoggingIn':
            return 'Logging in';
     
        }
    };

    const renderContent = () => {
        switch (mode) {
        case 'passwordUpdationSuccess':
            return (
                <div style={{ textAlign: 'center' }}>
                    <Text>Please use your account to log in.</Text>
                    <Button
                        variant="primary"
                        isFullWidth
                        style={{ marginTop: '90px' }}
                        onPress={validate}
                        type="submit"
                    >
                        Proceed to log in
                    </Button>
                </div>
            );
        case 'LoggingIn':
            return (
                <div style={{justifyContent:'center',alignItems:'center'}}>
                    <span>
                        <Text>New user? </Text>
                        <Link
                            onPress={() => {
                                //setMode('signup');
                                searchParams.set('location','signup')
                                window.location.search = searchParams.toString()
                            }}
                        >
                            Create account
                        </Link>
                    </span>
                    <Spinner style={{marginTop:"80px",marginLeft:"auto",marginRight:"auto"}} size="normal" />
                </div>
            ); // If LoggingIn doesn't need additional UI

        default: // Default case for login form
            return (
                <>
                    <Text>New user?</Text>
                    <Link
                        onPress={() => {
                            // setMode('signup');
                            updateQueryParam('location', 'signup');
                        }}
                    >
                        Create account
                    </Link>
                </>
            );
        }
    };

    useEffect(()=>{
        if(getQueryParam('location')){
            const page = getQueryParam('location') as ModeTypes;
            if(isModeType(page)){
                setMode(page)
            }
        }
        if(location.hash!==''){
       
            dispatch(handleLoggedInState(true))
        }

  
    },[])



    return(
        <Card className={[styles.card]}>
            {(getQueryParam('location')==='passwordUpdationSuccess'||getQueryParam('location')==='LoggingIn')?
                <div className={styles.container}>
                    <img src="https://wave-web-application-dev.s3.eu-central-1.amazonaws.com/TPV_Logo.png" alt="TPVision logo" width="176" height={'88'} />

     
                    <div
                        className={atomicFullWidth}
                    >
                        <h1 className={styles.title}>{getTitle()}</h1>
                        <div className={styles.subtitle}>{renderContent()}</div>

                    </div>
                    {
                        <footer className={styles.footer}>
                            <span className={styles.footerIcons}>
                                <OperatorsManualISO className={iconLarge} />
                                <CautionISO className={iconLarge} />
                            </span>
                            <div className={styles.footerText}>
                                Copyrights and all other proprietary rights in any software and related documentation (“Software”) made available to you rest exclusively with Philips 
                                or its licensors. No title or ownership in the Software is conferred to you. 
                                Use of the Software is subject to the end user license conditions as are available on request.
                            </div>
                        </footer>
                    }
                </div>
                :
                <div className={styles.container}>
                    <img src="https://wave-web-application-dev.s3.eu-central-1.amazonaws.com/TPV_Logo.png" alt="TPVision logo" width="176" height={'88'} />


                    <div
                        className={atomicFullWidth}
                        style={{marginBottom:"-70px"}}
                    >
                        <h1 className={styles.title}>{'Welcome to Wave Hospitality'}</h1>
                        <div className={styles.subtitle}>{'Please select the product'}</div>

                    </div>
                    <div style={{justifyContent:'center',alignItems:'center'}} >
                        <Button style={{'display':'block',width:"144px",marginBottom:"42px"}}>
                            Signage
                        </Button>
                        <Button onPress={loginWithRedirect} style={{'display':'block',width:"144px"}}>
                            ProTV
                        </Button>
                    </div>
                    <footer className={styles.footer}>
                        <span className={styles.footerIcons}>
                            <OperatorsManualISO className={iconLarge} />
                            <CautionISO className={iconLarge} />
                        </span>
                        <div className={styles.footerText}>
                            Copyrights and all other proprietary rights in any software and related documentation (“Software”) made available to you rest exclusively with Philips 
                            or its licensors. No title or ownership in the Software is conferred to you. 
                            Use of the Software is subject to the end user license conditions as are available on request.
                        </div>
                    </footer>
                </div>
              
            }
        </Card>
        
    )
}

